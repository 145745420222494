import { useEffect, useRef, useState } from "react";
import CTAFour from "../components/cta/CTAFour";
import CTAThree from "../components/cta/CTAThree";
import CTATwo from "../components/cta/CTATwo";
import ColoredCTA from "../components/cta/ColoredCTA";
import { CTAOne } from "../components/cta/CtaOne";
import DarkCTA from "../components/cta/DarkCTA";
import { DashboardShot } from "../components/features/DashboardShot";
import { FeaturesFour } from "../components/features/FeaturesFour";
import { FeaturesOne } from "../components/features/FeaturesOne";
import { FeaturesOneWithImage } from "../components/features/FeaturesOneWithImage";
import FeaturesThree from "../components/features/FeaturesThree";
import { FeaturesTwoWithImage } from "../components/features/FeaturesTwoWithImage";
import { MarketsShot } from "../components/features/MarketsShot";
import ColoredFooter from "../components/footer/ColoredFooter";
import FooterOne from "../components/footer/FooterOne";
import { Header } from "../components/header/Header";
import HeaderFive from "../components/header/HeaderFive";
import HeaderThree from "../components/header/HeaderThree";
import { Hero } from "../components/hero/Hero";
import HeroFive from "../components/hero/HeroFIve";
import HeroFour from "../components/hero/HeroFour";
import HeroThree from "../components/hero/HeroThree";
import HeroTwo from "../components/hero/HeroTwo";
import { PricingOne } from "../components/pricing/PricingOne";
import StatsOne from "../components/stats/StatsOne";
import StatsTwo from "../components/stats/StatsTwo";
import { TestimonialOne } from "../components/testimonials/TestimonialOne";
import { TestimonialTwo } from "../components/testimonials/TestimonialTwo";

const Main = () => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    const playVideo = () => {
      if (videoRef.current) {
        videoRef.current.play().catch((error) => {
          console.error("Autoplay was prevented:", error);
        });
      }
    };

    // Try to play the video when the component mounts
    playVideo();

    // Add event listeners for user interaction
    const handleInteraction = () => {
      if (!isPlaying) {
        playVideo();
        setIsPlaying(true);
      }
    };

    document.addEventListener("touchstart", handleInteraction);
    document.addEventListener("click", handleInteraction);

    return () => {
      document.removeEventListener("touchstart", handleInteraction);
      document.removeEventListener("click", handleInteraction);
    };
  }, [isPlaying]);

  return (
    <>
      <div style={{ backgroundColor: "#05080b", position: "relative" }}>
        <video
          ref={videoRef}
          loop
          muted
          playsInline
          className="absolute top-0 left-0 w-full h-full object-cover z-0"
        >
          <source src="/bg-video.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50 z-10"></div>
        <Header />
        <Hero />
      </div>
      {/*  */}
      <FeaturesOne />
      <div
        style={{
          backgroundColor: "#05080b",
          padding: "12px 0px",
        }}
      >
        <StatsTwo />
      </div>
      <MarketsShot />
      {/* <PricingOne /> */}
      <TestimonialOne />
      <DarkCTA />
      <FooterOne />
      {/* <HeroFive /> */}
      {/* <HeaderFive /> */}
      {/* <HeaderThree /> */}
      {/* <HeroFour /> */}
      {/* <HeroThree /> */}
      {/* <FeaturesTwoWithImage /> */}
      {/* <FeaturesThree /> */}
      {/* <StatsOne /> */}

      {/* <TestimonialTwo /> */}
      {/* <div style={{ backgroundColor: "#05080b" }}>
        <CTATwo />
      </div> */}
      {/* <CTATwo /> */}
      {/* <CTAOne /> */}
      {/* <CTAFour /> */}
      {/* <CTAThree /> */}
    </>
  );
};

export default Main;
